import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // For navigation handling
import './Navbar.css'; // Import CSS for styling
import logo from '../../assets/images/okapilogo.png';
import logo2 from '../../assets/images/okapifont.jpeg';
import logo3 from '../../assets/images/okapi42.png';


const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for mobile menu toggle
  const [isLoading, setIsLoading] = useState(false); // State for showing the loader
  const navigate = useNavigate(); // React Router navigation hook

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleNavigation = (path) => {
    setIsLoading(true); 
    setTimeout(() => {
      navigate(path); 
      setIsLoading(false); 
    }, 1000); 
  };

  return (
    <>
{isLoading && (
  <div className="loading-overlay">
    <div className="loading-content">
      <img
        src={logo}
        alt="Loading..."
        className="loading-logo"
        // style={{
        //   borderRadius: "20%", // Ensures circular shape
        //   height: 10, // Smaller height
        //   width: 10, // Matching width for a perfect circle
        //   objectFit: "cover", // Ensures the image fits inside the circle
        // }}
      />
      <div className="spinner"></div>
    </div>
  </div>
)}


      <nav className="navbar">
        {/* Logo Section */}
        <div className="navbar-logo">
          <img src={logo} alt="Okapi Logo" className="okapi_logo" />
          <img src={logo2} alt="Okapi Font" className="okapi_font" />
        </div>

        {/* Hamburger Menu Icon for Mobile View */}
        <button
          className="menu-toggle"
          onClick={toggleMenu}
          aria-label="Toggle Menu"
        >
          ☰
        </button>

        {/* Navbar Links */}
        <ul className={`navbar-links ${isMenuOpen ? 'active' : ''}`}>
          <li className="navbar-item">
            <button onClick={() => handleNavigation('/')}>Home</button>
          </li>
          <li className="navbar-item">
            <button onClick={() => handleNavigation('/about')}>About Okapi</button>
          </li>
          <li className="navbar-item">
            <button onClick={() => handleNavigation('/innovative_okapi')}>
              Innovative Okapi
            </button>
          </li>
          <li className="navbar-item">
            <button onClick={() => handleNavigation('/product')}>
              Products & Applications
            </button>
          </li>
          <li className="navbar-item">
            <button onClick={() => handleNavigation('/distributor')}>
              Distributor
            </button>
          </li>
          <li className="navbar-item">
            <button onClick={() => handleNavigation('/contact')}>Contact</button>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;